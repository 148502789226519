import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Nav from './Nav'
import BackgroundImage from 'gatsby-background-image'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => {
  let ctaButton = ''
  if (large) {
    ctaButton = (
      <Link to="/contact">
        <button className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg self-center">
          Contact Us
        </button>
      </Link>
    )
    className += 'h-screen '
  }
  return (
    <div>
      <BackgroundImage
        className={`bg-center bg-fixed bg-no-repeat  bg-cover  relative bg-gray-900 bg-opacity-50 text-white ${className}`}
        fluid={backgroundImage?.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        <Nav />
        <div className={`${large ? ' py-24 md:py-8' : 'py-24'} md:px-48`}>
          <div className="container m-auto flex  flex-wrap flex-col md:flex-row items-center justify-center">
            <div
              className="flex flex-col w-full md:w-2/5 justify-center items-center text-center "
              data-sal="slide-up"
              data-sal-duration="400"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h1 className="my-4 text-xl md:text-5xl font-bold leading-tight">
                {title}
              </h1>
              <p className="leading-normal text-sm md:text-2xl mb-8">
                {subtitle}
              </p>
              {ctaButton}
            </div>
            <svg
              class="animate-bounce w-6 h-6 text-orange-sherpa absolute bottom-0 mb-5"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
            </svg>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
